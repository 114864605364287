import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <>
      <div
        style={{
          textAlign: "center",
          fontFamily: "Arial, sans-serif",
          color: "#333",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "red" }}>Pick Your Vehicle!</h1>
        <p style={{ fontSize: "1.2em" }}>
          Fill out our quick application to see if you qualify for up to{" "}
          <strong style={{ color: "red", fontSize:"2.0em" }}>$5000 refund</strong>
        </p>
        <p style={{ fontSize: "0.9em", color: "gray" }}>
          <em>*O.A.C (on approved credit) | O.S.V (on select vehicles)</em>
        </p>
      </div>
    </>
      <Header data={landingPageData.Header} />
    </div>
  );
};

export default App;
